import { render, staticRenderFns } from "./DataSection.vue?vue&type=template&id=e9d04078&scoped=true&"
import script from "./DataSection.vue?vue&type=script&lang=ts&"
export * from "./DataSection.vue?vue&type=script&lang=ts&"
import style0 from "./DataSection.vue?vue&type=style&index=0&id=e9d04078&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e9d04078",
  null
  
)

export default component.exports